import 'regenerator-runtime/runtime'
import api from "@/services/apiService"

class GameService {

  getGames() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'game')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  testConnect(username, productId, code) {
    return new Promise((resolve, reject) => {
      api.get(`https://api.all123th.com/webhook/api?productId=${productId}&key=${username}&code=${code}`)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }
}

export default new GameService()
