<template>
  <div class="game-cats">

    <div class="row no-gutters">
      <div v-for="item in items" :key="item._id" class="col-4 col-md-2">
        <div class="game-item text-center" @click="play(item)">
          <img class="game-img" :src="item.img">
          <br /><span class="game-title">{{ item.name }}</span>
        </div>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'

export default {
  name: 'GameCategories',
  data() {
    return {
      isLoading: false,
      data: null
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    username() {
      return this.UserInfo?.username
    },
    items() {
      if(!this.data)
        return []

      return this.data
    }
  },
  methods: {
    getGames() {
      this.isLoading = true
      GameService.getGames()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    play(item) {
      // this.isLoading = true
      // GameService.testConnect(this.username, item.productId, item.code)
      // .then((response)=>{
      //   console.log(response)
      // })
      // .catch((e)=>{
      //   cAlert({
      //     ...e,
      //     title: 'ผิดพลาด!',
      //     text: 'กรุณาลองใหม่อีกครั้ง',
      //     icon: 'error',
      //     confirmButtonText: 'OK'
      //   })
      // })
      // .finally(()=>{
      //   this.isLoading = false
      // })
      window.open('https://m.pgjksjk.com/28/index.html?l=th&btt=1&ot=A1767FC2-3CAE-47A3-BADB-9D67FF687590&ops=00se9n824_test01', 'PlayPopup', 'popup')
    }
  },
  mounted() {
    this.getGames()
  }
}
</script>
<style lang="scss">
.game-cats {
  padding: 15px;

  .game-item {
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #FFFFFF;

    .game-img {
      max-width: 100%;
    }

    .game-title {
      font-size: 90%;
    }
  }

  .game-item:hover {
    background-color: #FFE1A1;
    border: 1px solid #E5881E;
  }
}
</style>
